@charset "UTF-8";

 @font-face {
  font-family: 'kallithea';
  /* NOTE: relative font paths have been adjusted to compensate for where the */
  /*       content of this CSS file ends up being used */
  src: url('../fontello/font/kallithea.eot?96450582');
  src: url('../fontello/font/kallithea.eot?96450582#iefix') format('embedded-opentype'),
       url('../fontello/font/kallithea.woff2?96450582') format('woff2'),
       url('../fontello/font/kallithea.woff?96450582') format('woff'),
       url('../fontello/font/kallithea.ttf?96450582') format('truetype'),
       url('../fontello/font/kallithea.svg?96450582#kallithea') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'kallithea';
    src: url('../fontello/font/kallithea.svg?96450582#kallithea') format('svg');
  }
}
*/
 
 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "kallithea";
  font-style: normal;
  font-weight: normal;
  speak: none;
 
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
 
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
 
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
 
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
 
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
 
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}
 
.icon-circle:before { content: '\2022'; } /* '•' */
.icon-right-open:before { content: '\2190'; } /* '←' */
.icon-up-open:before { content: '\2191'; } /* '↑' */
.icon-left-open:before { content: '\2192'; } /* '→' */
.icon-down-open:before { content: '\2193'; } /* '↓' */
.icon-code:before { content: '\2194'; } /* '↔' */
.icon-sort:before { content: '\2195'; } /* '↕' */
.icon-exchange:before { content: '\21c4'; } /* '⇄' */
.icon-plus-circled:before { content: '\2295'; } /* '⊕' */
.icon-minus-circled:before { content: '\2296'; } /* '⊖' */
.icon-diff-added:before { content: '\229e'; } /* '⊞' */
.icon-diff-removed:before { content: '\229f'; } /* '⊟' */
.icon-diff-modified:before { content: '\22a1'; } /* '⊡' */
.icon-clock:before { content: '\231a'; } /* '⌚' */
.icon-triangle-left:before { content: '\23f4'; } /* '⏴' */
.icon-triangle-right:before { content: '\23f5'; } /* '⏵' */
.icon-triangle-up:before { content: '\23f6'; } /* '⏶' */
.icon-triangle-down:before { content: '\23f7'; } /* '⏷' */
.icon-circle-empty:before { content: '\25e6'; } /* '◦' */
.icon-heart-empty:before { content: '\2661'; } /* '♡' */
.icon-heart:before { content: '\2665'; } /* '♥' */
.icon-cog-alt:before { content: '\2699'; } /* '⚙' */
.icon-database:before { content: '\26c3'; } /* '⛃' */
.icon-gear:before { content: '\26ed'; } /* '⛭' */
.icon-plus:before { content: '\2795'; } /* '➕' */
.icon-minus:before { content: '\2796'; } /* '➖' */
.icon-resize-vertical:before { content: '\2b65'; } /* '⭥' */
.icon-move-up:before { content: '\2b71'; } /* '⭱' */
.icon-move-down:before { content: '\2b73'; } /* '⭳' */
.icon-file-submodule:before { content: '\e801'; } /* '' */
.icon-git-merge:before { content: '\e802'; } /* '' */
.icon-ruler:before { content: '\e811'; } /* '' */
.icon-download-cloud:before { content: '\e813'; } /* '' */
.icon-upload-cloud:before { content: '\e814'; } /* '' */
.icon-graph:before { content: '\e815'; } /* '' */
.icon-file-zip:before { content: '\e816'; } /* '' */
.icon-file-code:before { content: '\e81a'; } /* '' */
.icon-doc-text-inv:before { content: '\e81c'; } /* '' */
.icon-diff:before { content: '\e81d'; } /* '' */
.icon-diff-ignored:before { content: '\e81e'; } /* '' */
.icon-diff-renamed:before { content: '\e81f'; } /* '' */
.icon-paste:before { content: '\e820'; } /* '' */
.icon-file-directory:before { content: '\e823'; } /* '' */
.icon-git-compare:before { content: '\e824'; } /* '' */
.icon-git-pull-request:before { content: '\e825'; } /* '' */
.icon-repo-forked:before { content: '\e827'; } /* '' */
.icon-fork:before { content: '\e828'; } /* '' */
.icon-docs:before { content: '\e834'; } /* '' */
.icon-doc-inv:before { content: '\e836'; } /* '' */
.icon-file-powerpoint:before { content: '\e837'; } /* '' */
.icon-tags:before { content: '\e83d'; } /* '' */
.icon-bookmark-empty:before { content: '\e83f'; } /* '' */
.icon-bookmark:before { content: '\e840'; } /* '' */
.icon-align-left:before { content: '\e841'; } /* '' */
.icon-sliders:before { content: '\e843'; } /* '' */
.icon-eye-off:before { content: '\e851'; } /* '' */
.icon-rss:before { content: '\e855'; } /* '' */
.icon-rss-squared:before { content: '\e856'; } /* '' */
.icon-strike:before { content: '\e859'; } /* '' */
.icon-box:before { content: '\e85e'; } /* '' */
.icon-right:before { content: '\e85f'; } /* '' */
.icon-left:before { content: '\e860'; } /* '' */
.icon-left-circled:before { content: '\e861'; } /* '' */
.icon-right-circled:before { content: '\e862'; } /* '' */
.icon-up-circled:before { content: '\e863'; } /* '' */
.icon-up:before { content: '\e864'; } /* '' */
.icon-down:before { content: '\e865'; } /* '' */
.icon-down-circled:before { content: '\e866'; } /* '' */
.icon-globe:before { content: '🌎'; } /* '\1f30e' */
.icon-eye:before { content: '👁'; } /* '\1f441' */
.icon-user:before { content: '👤'; } /* '\1f464' */
.icon-users:before { content: '👥'; } /* '\1f465' */
.icon-floppy:before { content: '💾'; } /* '\1f4be' */
.icon-folder-empty:before { content: '📁'; } /* '\1f4c1' */
.icon-folder-open-empty:before { content: '📂'; } /* '\1f4c2' */
.icon-clippy:before { content: '📋'; } /* '\1f4cb' */
.icon-book:before { content: '📒'; } /* '\1f4d2' */
.icon-search:before { content: '🔍'; } /* '\1f50d' */
.icon-keyhole-circled:before { content: '🔐'; } /* '\1f510' */
.icon-key:before { content: '🔑'; } /* '\1f511' */
.icon-lock:before { content: '🔒'; } /* '\1f512' */
.icon-lock-open-alt:before { content: '🔓'; } /* '\1f513' */
.icon-tag:before { content: '🔖'; } /* '\1f516' */
.icon-wrench:before { content: '🔧'; } /* '\1f527' */
.icon-pencil:before { content: '🖉'; } /* '\1f589' */
.icon-pencil-squared:before { content: '🖊'; } /* '\1f58a' */
.icon-edit:before { content: '🖋'; } /* '\1f58b' */
.icon-folder:before { content: '🗀'; } /* '\1f5c0' */
.icon-folder-open:before { content: '🗁'; } /* '\1f5c1' */
.icon-doc:before { content: '🗅'; } /* '\1f5c5' */
.icon-doc-text:before { content: '🗈'; } /* '\1f5c8' */
.icon-trashcan:before { content: '🗑'; } /* '\1f5d1' */
.icon-arrows-cw:before { content: '🗘'; } /* '\1f5d8' */
.icon-comment:before { content: '🗩'; } /* '\1f5e9' */
.icon-comment-discussion:before { content: '🗪'; } /* '\1f5ea' */
.icon-cancel:before { content: '🗴'; } /* '\1f5f4' */
.icon-cancel-circled:before { content: '🗵'; } /* '\1f5f5' */
.icon-ok:before { content: '🗸'; } /* '\1f5f8' */
.icon-ok-circled:before { content: '🗹'; } /* '\1f5f9' */
.icon-block:before { content: '🚫'; } /* '\1f6ab' */
.icon-tools:before { content: '🛠'; } /* '\1f6e0' */